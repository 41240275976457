

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

ul{
  padding:20px;
  margin: 0;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
  font-family: 'space_ageregular', sans-serif;
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: "Anaheim", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}