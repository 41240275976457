:root {
  --main-bg-color: #000000;
  --primary-color: #E900FF;
  --secondary-color: #5800FF;
}

.App {
  text-align: center;
  background-color: var(--main-bg-color);
  color: #fff;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.text-fullwidth{
  width: 100%;
  padding:24px;
}

.submit-button {
  border-radius: 8px;
  color: white;
  padding: 12px 20px;
  margin: 16px 0px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.submit-button-primary {
  background-color: var(--primary-color);
}

.submit-button-secondary {
  background-color: var(--secondary-color);
}

.App-header {
  min-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header h2{
  font-size: 16px;
}


.App-link {
  color: #61dafb;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 750px;

}
.row-left{
  flex: 1;
}
.row-right{
  flex: 1;

}

.text-box{
  width: 100%;
  height: 100%;
  padding: 60px;
  color: #fff;
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align:left;
}

.center-cropped {
  width: 100%;
  height: 750px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 900px 900px;
}

.primary-color {
  color: var(--primary-color);
}

.center-cropped video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pricing{
  width: 100%;
  margin: 48px 0px;
}

.pricing-blocks {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
}

.pricing-block {
  width: 100%;
  margin-top:24px;
}

.pricing h4, .demo-block h4{
  font-family: "Anaheim", sans-serif;
  font-weight:600;
  font-size: 24px;
}

.price {
  margin:0;
  padding:0;
}

.demo-block{
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer{
  width: 100%;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 760px) {
  .row {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;  
    height: 100%;
  }

  .text-box{
    padding: 20px;
    font-size: 16px;
  }
  .center-cropped {
    height: 400px;
  }
  .row-reversed-mobile{
    flex-direction: column-reverse;
  }

  .center-cropped{
    background-size: 100vw 100vw;
  }

  .pricing-blocks {
    flex-direction: column;
  }

  .pricing-blocks :nth-child(1) { order: 2; }
  .pricing-blocks :nth-child(2) { order: 1; }
  .pricing-blocks :nth-child(3) { order: 3; }
  
}
 